import { Person, PeopleApi, ClubApi } from 'api'
import { TopLevelPage } from 'ui'
import { withErrorBoundary, connected, SearchBar, Formatter } from 'shared'
import { Spinner, NoResults } from 'shared'
import { absolutizePath } from 'shared/util'
import { SmartButton, SmartButtonLink } from 'ui/smart-buttons'
import SmartButtonApi  from 'api/smart-button-api'

class PeopleList extends Component {
  static propTypes = {
    match: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.query = PeopleApi.paramsFor('index').q
  }

  render() {
    let { id, club_id, args } = this.props.match.params
    let { smartButtons, reloadSmartButtons } = this.props
    let base
    if (club_id){
      base = args ? `people::${args}` : `people/club/${club_id}`
    }
    else{
      base = args ? `people::${args}` : `people`
    }

    let urlParams = new URLSearchParams(args)
    let userRoleId = urlParams.get('role')
    let club_models = ClubApi.kick('joined')
    let models = PeopleApi.kick('index', { q: this.query, user_role_id: userRoleId, club_id: club_id })

    return (
      <TopLevelPage className="People" modelId={id} base={'/' + base}>
        <div className="entity-smart-buttons smart-buttons">
          {smartButtons && smartButtons.map(smartButton =>
            <SmartButton {...{smartButton, reloadSmartButtons, base}} key={smartButton.key} returnPath={application.path} />
          )}
        </div>

        <div className="row">
          <div className="col-sm-6 col-lg-4 col-xl-3">
            <SearchBar onChange={this.queryDidChange} onSubmit={this.queryWouldSubmit} initialQuery={this.query} />
          </div>
        </div>
        <div className="entity-smart-buttons smart-buttons my-4 ">
          { club_models == null ? <Spinner /> :
            club_models.length == 0  ? <NoResults>You have not joined any club.</NoResults> :
            <div>
            {
              club_models.map(club =>
                // <Link text={`${club.name}-Members`} className="admin-purple inverted primary" key={`${club.id}`} to={`people/${club.id}`} />
                <Link to={`/people/club/${club.id}`} key={`${club.id}`} className='smart-button btn'>
                  {`${club.name}-Members`}
                </Link>
                )
            }
              <Link to={`/people`} className='btn btn-back'>
                {`Reset`}
              </Link>
            </div>
          }
        </div>
        <div>
          {
            models == null ? <Spinner /> :
            models.length == 0 ? <NoResults /> :
              <table className="person-table table">
                <tbody>
                  {
                    models.map(user =>
                      <tr key={user.uid} className="person-table-row">
                        <td className="person-avatar-cell">
                          {
                            user.picture &&
                            <Link to={`people/${user.uid}`} className='plain-link'>
                              <img src={user.picture} className="person-avatar rounded-circle" />
                            </Link>
                          }
                        </td>
                        <td>
                          { club_id ?
                          <Link to={`${club_id}/${user.uid}`} className='plain-link'>
                            {user.name}
                          </Link>
                           :
                          <Link to={`people/${user.uid}`} className='plain-link'>
                            {user.name}
                          </Link>

                          }
                        </td>
                        <td>{user.phone}</td>
                        <td>{user.email}</td>
                        <td className="person-followers-cell">
                          {user.result_based_on_settings_identifier}
                        </td>
                      </tr>
                     )
                  }
                </tbody>
              </table>
           }
        </div>
      </TopLevelPage>
    )
  }

  queryDidChange = query => { this.query = query }
  queryWouldSubmit = () => { this.forceUpdate() }
}

PeopleList.requestSmartButtons = (success) => {
  return SmartButtonApi.forUsers(success)
}

export default SmartButton.containerComponent(null, withErrorBoundary(connected(PeopleList)), { Source: PeopleList })
