const weekDataFormats = {
  2: 'dd',
  3: 'ddd',
  full: 'dddd',
}

const FORMATS = {
  usDateYYYY: 'MM/DD/YYYY',
  usDateYY: 'MM/DD/YY',
  usDateTime: 'MM/DD/YYYY hh:mm A',
  longest: 'MMMM D, YYYY',
  long: 'MMM D, YYYY',
}

const Durations = {
  15 : '15 Minutes',
  20 : '20 Minutes',
  30 : '30 Minutes',
  40 : '40 Minutes',
  45 : '45 Minutes',
  60 : '1 Hour',
  90 : '1.5 Hours',
  120: '2 Hours',
  180: '3 Hours',
}

class Formatter {
  hhmmToAmpmHour(hhmm) {
    return hhmm ? moment(hhmm, "HH:mm").format('h A') : null
  }

  hhmmToAmpm(hhmm) {
    return hhmm ? moment(hhmm, "HH:mm").format('h:mm A') : null
  }

  militaryToHHMM(mil) {
    return `${mil[0]}${mil[1]}:${mil[2]}${mil[3]}`
  }

  dateToWeekDay(dateStr, options = { format: 3, today: true }) {
    if (dateStr == null) return null
    if (dateStr == 'undefined') return 'N/A'
    if (options.today && this.dateToToday(dateStr)) return this.dateToToday(dateStr)
    return moment(dateStr).format(weekDataFormats[options.format])
  }

  dateToToday(dateStr) {
    if (dateStr == App.todayDateString) return 'Today'
    if (dateStr == App.tomorrowDateString) return 'Tomorrow'
    return null
  }

  date(dateStr, format) {
    if (dateStr == 'undefined') return 'N/A'
    return dateStr ? moment(dateStr).format(FORMATS[format] || 'YYYY') : null
  }

  dateOrToday(dateStr, format) {
    return this.dateToToday(dateStr) || this.date(dateStr, format)
  }

  dateUS(dateStr)     { return this.date(dateStr, 'usDateYY') }
  usDateTime(dateStr) { return this.date(dateStr, 'usDateTime') }

  amountFromCents(cents, { free = false } = { }) {
    return cents && cents != 0 ? '$' + (cents / 100).toFixed(2) : free ? 'FREE' : null
  }

  simpleFormat(text) {
    return (text || '').replace(/\n/g, '<br/>')
  }

  duration(minutes) {
    return Durations[minutes] || `${minutes} Minutes`
  }
}

export default new Formatter
