import { GradientedScroller, connected, withErrorBoundary, NoResults} from 'shared'
import { TopLevelPage, ModelGrid } from 'ui'
import EventCard from 'ui/events/EventCard'
import ParkCard from 'ui/parks/ParkCard'
import { EventApi, AnnouncementApi, Park, ParkApi, Menu, User } from 'api'
import { DateRangePicker } from 'react-date-range';
import { addDays, format, startOfMonth, endOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import Announcements from '../events/Announcements'

calendarGlobal = []
intervalId = null

CalendarView = ({match}) ->
  feedItemId = match.params.id
  [calendar, setCalendar] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection'
    }
  ]);
  [events, setEvents] = useState([])
  [reservations, setReservations] = useState([])
  [openAvailable, setOpenAvailable] = useState([])
  announcements = AnnouncementApi.kick('index')

  parseDate = (dateTime) =>
    format(dateTime, "yyyy'-'MM'-'dd")

  dateParams = (calendar) =>
    {
      start_date: parseDate(calendar.startDate),
      end_date: parseDate(calendar.endDate)
    }

  loadData = (calendar = null) =>
    calendar = { startDate: startOfMonth(new Date()), endDate: endOfMonth(new Date()) } unless calendar
    EventApi.filtered(dateParams(calendar)).then (data) =>
      setEvents(data)
    ParkApi.openAvailable(dateParams(calendar)).then (data) =>
      setOpenAvailable(data)

  updateDateRange = (range) =>
    setCalendar([range.selection])

  useEffect(() =>
    calendarGlobal = calendar
    loadData(calendarGlobal[0])
    clearInterval(intervalId)  if intervalId
    intervalId = setInterval(() =>
      loadData(calendarGlobal[0])
    , 5 * 60 * 1000 )
    return # Don't remove this line
  , [calendar])

  <TopLevelPage className="Calendar" modelId={feedItemId} base="/community-calendar">
    <section className="">
      <div className="row justify-content-center">
        <div className="col-md-8 separator">
          {
            if announcements
              <Announcements announcements={announcements} base="/community-calendar" />
          }
        </div>
      </div>
    </section>
    <div className="row calendar-section justify-content-center mt-3">
      <div className="col text-center">
        <DateRangePicker
          onChange={updateDateRange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={calendar}
          direction="horizontal"
          inputRanges={[]}
        />
      </div>
    </div>
    <section>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h1 className="section-heading-lowercase my-3"> Community Events </h1>
        </div>
      </div>
      {
        if (events.length == 0)
          <NoResults>We’re sorry, this section contains no events.</NoResults>
        else
          <div className="container">
          <GradientedScroller containerClassName="feed-columns row">
            {
              for model in events
                <section className="feed-column col-sm-6 col-lg-4 col-xl-3" key={model.uid}>
                  <div className="feed-column-body">
                    {
                        if model instanceof Park
                          <ParkCard park={model} key={model.uid} base='/community-calendar' />
                        else
                          <EventCard event={model} key={model.id} base='/community-calendar' />
                    }
                  </div>
                </section>
            }
          </GradientedScroller>
          </div>
      }
    </section>
    {### remove this section in 4.7 release feedback
      User.current && User.current.regular &&
      <section>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h1 className="section-heading my-3"> Current Reservations </h1>
          </div>
        </div>
        <div className="row">
          {
            if (reservations.length == 0)
              <NoResults>We’re sorry, this section contains no pages.</NoResults>
            else
              for model in reservations
                <div className="col-sm-6 col-lg-4 col-xl-3" key={model.uid}>
                  <ParkCard park={model} base='/community-calendar' />
                </div>
          }
        </div>
      </section>
    ###
    }
    <section>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h1 className="section-heading-lowercase my-3">Today's Open Availability </h1>
        </div>
      </div>

      <div className="row">
        { if (openAvailable.length == 0)
            <NoResults>All reservable amenities are currently reserved for today.</NoResults>
          else
            for model in openAvailable
              <div className="col-sm-6 col-lg-4 col-xl-3" key={model.uid}>
                <ParkCard park={model} base='/community-calendar'/>
              </div>
        }
      </div>
    </section>

  </TopLevelPage>

export default withErrorBoundary connected CalendarView
