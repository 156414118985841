import { Formatter, Spinner } from 'shared'
import { SmartButtonStub, SmartButtonLink } from 'ui/smart-buttons'
import { ParkApi, SmartButtonApi, Model } from 'api'
import ParkReservationSlots from './ParkReservationSlots'
import ParkReservationCalendar from './ParkReservationCalendar'
import ParkReservationDetails from './ParkReservationDetails'
import ParkServiceSelector from './ParkServiceSelector'

export default class ParkReservationBlock extends Component {
  state = { date: null, time: null, timeSlots: null }

  componentDidMount() {
    this.loadTimeslots()
    this.componentDidUpdate({})
  }

  async componentDidUpdate(prevProps) {
    let prevSubroute = prevProps.subroute
    let currSubroute = this.props.subroute
    if (prevSubroute == currSubroute)
      return

    let [ prevServiceId, prevDateStr, prevTime ] = Model.extractSubrouteArgs(prevSubroute)
    let [ currServiceId, currDateStr, currTime ] = Model.extractSubrouteArgs(currSubroute)
    let prevDate = prevDateStr && moment(prevDateStr)
    let currDate = currDateStr && moment(currDateStr)

    if (currServiceId == 'NA')
      currServiceId = null
    this.setState({ date: currDate, time: currTime, timeSlots: null, serviceId: currServiceId })
    if (currDate && (this.loadedDate != currDate || this.loadedServiceId != currServiceId)) {
      this.loadedDate = currDate
      this.loadedServiceId = currServiceId
      this.setState({ timeSlots: await ParkApi.loadTimeSlotsForDate(this.props.park, currDate, currServiceId) })
    }
  }

  render() {
    let { park, base, subroute, reloadSmartButtons } = this.props
    let { date, time, timeSlots, serviceId } = this.state
    let service = park.getService(serviceId)

    let hhmm, slot
    if (time) {
      hhmm = Formatter.militaryToHHMM(time)
      slot = timeSlots && timeSlots.find(ts => ts.time == hhmm)
    }

    return (
      <div className="park-reservation-block">
      {
        time ?
          slot && <ParkReservationDetails {...{park, base, subroute, service, serviceId, date, time, slot}} reserve={this.reserveSlot} />
          :
          park.usesSimpleReservation || service  ?
            <>
              <ParkReservationCalendar {...{park, base, date, service, serviceId}} />
              <ParkReservationSlots {...{park, base, subroute, service, serviceId, date, timeSlots}} reserve={this.reserveSlot} cancel={this.cancelSlot} />
            </>
          :
            <ParkServiceSelector {...{park, base, date}} />
      }
      </div>
    )
  }

  reserveSlot = async (timeSlot, {tickets, serviceId, success} = {}) => {
    let { park, reloadSmartButtons } = this.props
    let timeSlots = await ParkApi.reserveTimeSlot(park, timeSlot, tickets, serviceId)
    if (timeSlots) this.setState({ timeSlots: timeSlots })
    reloadSmartButtons()
    EventApi.reset('mineGrouped')
    success && success()
  }

  cancelSlot = async (timeSlot) => {
    let { park, reloadSmartButtons } = this.props
    let { date, serviceId } = this.state
    let timeSlots = await ParkApi.cancelTimeSlot(park, timeSlot)
    reloadSmartButtons()
    EventApi.reset('mineGrouped')
    this.setState({ timeSlots: await ParkApi.loadTimeSlotsForDate(park, date, serviceId) })
  }

  loadTimeslots() {
    if (this.props.park.fully_reserved_dates == undefined)
      ParkApi.show(this.props.park.id)
  }
}
