import { Formatter } from 'shared'
import ApiModel from 'base/api-model'
import Event           from './event'
import ParkReservation from './park-reservation'
import GuestPassApi    from './guest-pass-api'
import SmartButtonApi  from './smart-button-api'
import * as $U from 'shared/util'

export default class Park extends ApiModel {
  constructor(attributes) {
    super(attributes)
    if (this.reservations)
      this.reservations = this.reservations.map(res => new ParkReservation(res))

    if (this.fully_reserved_dates)
      this.fullyReservedDates = this.fully_reserved_dates.map(d => moment(d))
  }

  get uid() { return `${TypeMapping[this.type] || this.type}:${this.id}` }
  get baseCollectionName() { return 'parks' }
  get isPark() { return true }
  get isClub() { return false }
  get isJoinRequired() { return this.is_join_required }
  get coords() { return this.latitude ? { lat: this.latitude, lng: this.longitude } : null }
  get description() { return this.sub_text }
  get image_preview_url() { return this.resources && this.resources.length ? this.resources[0].preview : null }
  get image_url() { return this.resources && this.resources.length ? this.resources[0].file : null }

  get has_events() { return this.events && this.events.length != 0 }
  get events() { return this.eventIds ? this.eventIds.map(id => App.state.getEvent(id)) : [] }
  set events(objects) {
    App.state.updateModels(objects.map(e => new Event(e)))
    this.eventIds = objects.map(e => e.id)
  }

  get needsReloading() { return this.eventIds == null || this.fully_reserved_dates == null }

  get mayRequirePayment() {
    return this.reservation_price > 0 || this.reservation_options && this.reservation_options.some(option => option.amount > 0)
  }

  get closedWeekdays() {
    let openWeekdays = Object.keys(this.open_periods)
    return $U.WeekDays.filter(d => !openWeekdays.includes(d))
  }

  get closedDates() {
    if (this._closedDates) return this._closedDates
    let results = []
    let closedWeekdays = this.closedWeekdays
    let startDate = application.now
    for (let i = 0; i <= 365; i++) {
      let date = startDate.clone().add(i, 'days')
      for (let weekday of closedWeekdays)
        if ($U.momentMatchWeekday(date, weekday)) {
          results.push(date)
          break
        }
    }
    return this._closedDates = results
  }

  get closedDateObjects() {
    return this.closedDates.map(m => m.toDate())
  }

  get closedAndBookedDates() {
    let { closedDates, fullyReservedDates } = this
    let moments = fullyReservedDates ? closedDates.concat(fullyReservedDates) : closedDates
    return moments.map(m => m.toDate())
  }

  bookedOnDate(date) {
    date = date && moment(date)
    if (!this.fullyReservedDates) return false
    return this.fullyReservedDates.some(d => d.isSame(date))
  }

  get badge() {
    if (this.isReservationApproved) return "Reserved"
    return null
  }

  get opening_status_str() { return this.is_open_now ? 'Open Now' : 'Closed' }

  get requiresReservationDetails() {
    return this.reservation_price || !_.isEmpty(this.reservation_options) || !_.isEmpty(this.services)
  }

  opening_hours_for(weekday) {
    let period = this.open_periods[weekday]
    if (!period) return null
    return `${Formatter.hhmmToAmpm(period.open)}–${Formatter.hhmmToAmpm(period.close)}`
  }

  requestSmartButtons(success) {
    if (this.type == "retail:details") return SmartButtonApi.forRetail(this, success)
    return SmartButtonApi.forPark(this, null, success)
  }

  async toggleReservation() {
    console.error("Old place reservation logic is used!")
  }

  async enroll() { await ParkApi.enroll(this) }
  async leave() { await ParkApi.leave(this) }

  async saveToGuestPass() {
    await GuestPassApi.postPlace(this)
  }

  isFeatureEnabled(feature) { return this.ui_features.includes(feature) }

  get hasServices() { return !_.isEmpty(this.services) }
  get usesSimpleReservation() { return !this.hasServices }

  getService(serviceId) {
    if (!this.hasServices) return null
    return this.services.find(s => s.id == Number(serviceId) )
  }
}

const TypeMapping = {
  'place:details': 'park'
}
