import { Redirect } from 'react-router-dom'
import { connected, withErrorBoundary, Spinner, NoResults, Formatter, GradientedScroller } from 'shared'
import { EventApi, AnnouncementApi, Park, ParkApi, Menu, User } from 'api'
import { TopLevelPage, ModelGrid } from 'ui'
import { SmartButtonFeedList } from 'ui/smart-buttons'
import { ChatIcon } from 'customizations'
import EventCard from './EventCard'
import Announcements from './Announcements'
import ParkCard from '../parks/ParkCard'

HomeFeed = ({ match }) ->
  feedItemId = match.params.id
  # if App.disableHomeFeed
  #   defaultMenu = Menu.defaultMenu
  #   return if defaultMenu then <Redirect to={defaultMenu.route} /> else <Spinner />

  groups = EventApi.kick('mineGrouped')
  announcements = AnnouncementApi.kick('index')
  parks = ParkApi.kick('mine')

  return <Spinner /> unless groups

  <TopLevelPage className="HomeFeed" fullWidth={true} modelId={feedItemId} base="/home">
    {
      User.homeFeedBanner &&
      <img className="hero-image show" src={ User.homeFeedBanner }>
      </img>
    }
    <section className="intro">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="wrap">
              <h1 className="home-feed-header">{ User.homeFeedHeader } </h1>
              <p>
                <span dangerouslySetInnerHTML={{__html: Formatter.simpleFormat User.homeFeedSubText}}></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="container">
      {
        if announcements
          <Announcements announcements={announcements} base="/home" />
      }
      {
        if parks
          <>
            <div className="row justify-content-center parks-header-container">
              <div className="col-md-8">
                <h1 className="parks-header">Discover Community Happenings and Amenities </h1>
              </div>
            </div>
            <ModelGrid models={parks} base={'/home'} />
          </>
      }
      {
        if _.isEmpty(groups)
          <NoResults className='compact'><NoResultsMessage /></NoResults>
        else
          <>
            <div className="row justify-content-center parks-header-container">
              <div className="col-md-8">
                <h1 className="upcoming-events-header">Upcoming Events For You</h1>
              </div>
            </div>
            <GradientedScroller containerClassName="feed-columns row mt-5">
              {
                for date in Object.keys(groups).sort()
                  <section className="feed-column col-sm-6 col-lg-4 col-xl-3" key={date}>
                    <header className="feed-column-header">
                      <b>{Formatter.dateToWeekDay(date, {format: 'full'})}</b>
                      <time dateTime={date}>{Formatter.date(date, 'usDateYYYY')}</time>
                      <div className="feed-column-border">
                        <span className="feed-column-circle"></span>
                        <span className="feed-column-line"></span>
                      </div>
                    </header>
                    <div className="feed-column-body">
                      {
                        for model in groups[date]
                          if model instanceof Park
                            <ParkCard park={model} key={model.uid} base='home' />
                          else
                            <EventCard event={model} key={model.uid} base='home' />
                      }
                    </div>
                  </section>
              }
            </GradientedScroller>
          </>
      }
    </div>
  </TopLevelPage>


NoResultsMessage = ->
  appName = application.assets.get('app_name')
  text = if User.guest
    App.guestPassSettings.welcome_text
  else
   "Curating the #{appName} experience just for you.
    The more you use the app, the more the app learns about you.
    Your personalized #{appName} feed will start appearing here shortly."

  <p dangerouslySetInnerHTML={{__html: Formatter.simpleFormat(text)}}></p>


export default withErrorBoundary connected HomeFeed
