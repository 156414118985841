export default class InfoNavbar extends Component {
  render() {
    return (
      <div className='info-navbar-links mb-3'>
        {/*<NavLink className="smart-button primary" to="/settings/documents">Forms & Documents</NavLink>*/}
        <NavLink className="smart-button primary" to="/settings/payment">Payment Method</NavLink>
        <NavLink className="smart-button primary" to="/settings/privacy">Privacy Settings</NavLink>
      </div>
    )
  }
}
