import { Api } from 'base'
import Person from './person'

class PeopleApi extends Api {
  collectionName = 'people'

  async index(params) {
    let json = await Backend.get('/users', params)
    return this.state.triggerNone(_ =>
      json.users.map(r => new Person(r))
    )
  }

  async show(id) {
    return this.receiveModel(await Backend.get(`/users/${id}`))
  }

  async linked_users(params) {
    let json = await Backend.get('/users/linked_users', params)
    return this.state.triggerNone(_ =>
      json.users.map(r => new Person(r))
    )
  }

  receiveModel(json) {
    let model = new Person(json.user)
    this.state.updateModel(model)
    return model
  }
}

export default new PeopleApi
